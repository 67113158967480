<template>
  <div class="cubes-container" :class="{ openOptions: showOptions }">
    <div
      class="cubes-wrapper"
      :class="[{ 'cubes-preview': !!preview, [`${preview}-view`]: preview }]"
    >
      <div class="cubes-base" :style="baseStyles" :class="{ animationActive }">
        <div class="cubes-column" v-for="(col, ci) of columns" :key="ci">
          <Cube
            class="cube-module"
            :class="{ 'cube-selected': selColIx === ci && selModIx === mi }"
            @click="openModuleOptions(ci, mi)"
            v-for="(mod, mi) of col.modules"
            :key="`module_${mi}_${ci}`"
            :height="mod.body.height"
            :long="mod.body.long"
            :width="mod.body.width"
            :head="mod.head"
            :side="mod.side"
            :base_height="mod.body.base_height"
            :scale="scale"
            :isAdjacent="mi > 0"
            :isLeft="ci === 0"
            :isRight="ci === columns.length - 1"
            :isAlone="ci === 0 && ci === columns.length - 1"
            :longestColumn="fullSizes.long"
            :longSizeOffset="
              col.modules.some((x) => x.side?.is_outside) ? 80 : 40
            "
            :widthSizeOffset="getWidthSizeOffset(ci, mi)"
            :heightSizeOffset="(mod.side?.width || 0) + 40"
            :showLongSize="mod.body.sizes[preview]?.long"
            :showWidthSize="mod.body.sizes[preview]?.width"
            :showHeightSize="mod.body.sizes[preview]?.height"
            :preview="preview"
          />
        </div>
        <div class="total-sizes-wrapper">
          <div class="sizes-subwrapper">
            <div class="total-size-long" v-if="totalSizes[preview]?.long">
              <span>{{ fullSizes.long }}cm</span>
            </div>
            <div
              class="total-size-long-secondary"
              v-if="
                totalSizes[preview]?.long_secondary &&
                fullSizes.long_secondary &&
                fullSizes.long !== fullSizes.long_secondary
              "
            >
              <span>{{ fullSizes.long_secondary }}cm</span>
            </div>
            <div class="total-size-width" v-if="totalSizes[preview]?.width">
              <span>{{ fullSizes.width }}cm</span>
            </div>
            <div class="total-size-height" v-if="totalSizes[preview]?.height">
              <span>{{ fullSizes.height }}cm</span>
            </div>
          </div>
        </div>
      </div>
      <div class="preview-controls">
        <button
          @click="preview = null"
          class="btn-controls"
          :class="{ active: !preview }"
        >
          Vista 3D
        </button>
        <button
          @click="viewFromTop()"
          class="btn-controls"
          :class="{ active: preview === 'top' }"
        >
          Vista Superior
        </button>
      </div>
      <div class="controls">
        <button @click="$emit('addColumn')" class="btn-controls">
          Agregar modulo
        </button>
        <button
          @click="animationActive = !animationActive"
          class="btn-controls"
          :class="{ disabled: preview }"
        >
          <i
            :class="!animationActive ? 'fa-solid fa-play' : 'fa-solid fa-stop'"
          ></i>
        </button>
        <input
          type="range"
          min="-360"
          max="360"
          v-model="rotateZ"
          :class="{ disabled: preview }"
          class="range-controls"
        />

        <button @click="zoomIn" class="btn-controls">+</button>
        <button @click="zoomOut" class="btn-controls">-</button>
      </div>
    </div>
    <div class="cubes-props">
      <CubeColOpts
        v-if="showOptions"
        @close="closeOptions"
        :cube="columns[selColIx].modules[selModIx]"
        @onUpdate="updateModule"
        @onChangeHeight="updateHeight"
        @onChangeBaseHeight="updateBaseHeight"
        @onChangeHeadHeight="updateHeadHeight"
        @onChangeSideHeight="updateSideHeight"
        @onChangeWidth="updateWidth"
        @onChangeLong="updateLong"
        @onRemove="removeModule"
        @onAddExtra="addExtra"
        :key="`options_${selColIx}_${selModIx}`"
        :preview="preview"
        :total-size-indicators="totalSizes"
        :isAdjacent="selModIx > 0"
        :isLeft="selColIx === 0"
        :canHaveSide="
          selColIx === 0 ||
          selColIx === columns.length - 1 ||
          (selModIx > 0 && selModIx === columns[selColIx].modules.length - 1)
        "
      />
    </div>
  </div>
</template>

<script>
import Cube from "./Cube.vue";
import CubeColOpts from "./CubeColOpts.vue";

export default {
  name: "CubeContainer",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Cube,
    CubeColOpts,
  },
  data: () => ({
    scale: 1,
    rotateZ: -30,
    animationActive: false,
    showOptions: false,
    selColIx: null,
    selModIx: null,
    preview: null,

    // total sizes indicators
    totalSizes: {
      top: {
        height: false,
        width: true,
        long: true,
        long_secondary: true,
      },
      side: {
        height: true,
        width: false,
        long: true,
      },
      front: {
        height: true,
        width: true,
        long: false,
      },
    },
  }),
  computed: {
    baseStyles() {
      return `
      --rotateZ: ${this.rotateZ}deg;
      --total-long: ${this.fullSizes.long}px;
      --total-long-offset-top: ${this.fullSizes.longOffsetTop}px;
      --total-long-offset-bottom: ${this.fullSizes.longOffsetBottom}px;
      
      --total-long-sec: ${this.fullSizes.long_secondary * this.scale}px;
      --total-long-sec-offset-top: ${this.fullSizes.longSecOffsetTop}px;
      --total-long-sec-offset-bottom: ${this.fullSizes.longSecOffsetBottom}px;

      --total-width: ${this.fullSizes.width}px;
      --total-width-offset-start: ${this.fullSizes.widthOffsetStart}px;
      --total-width-offset-end: ${this.fullSizes.widthOffsetEnd}px;
      --total-width-offset-top: ${this.fullSizes.widthOffsetTop}px;
      --total-height: ${this.fullSizes.height}px;
      `;
    },
    fullSizes() {
      console.log("fullSizes calc");
      let height = 0;
      let width = 0;
      let long = 0;
      let colsLong = [];
      let widthOffsetStart = 0;
      let widthOffsetEnd = 0;
      let widthOffsetTop = 0;
      let longOffsetTop = 0;
      let longOffsetBottom = 0;

      this.columns.forEach((col, index) => {
        const mod = col.modules[0];

        // Get Width sizes
        const isFirst = index === 0;
        const isLast = index === this.columns.length - 1;

        if (isFirst || isLast) {
          if (col.modules.length > 1) {
            let widestHead = 0;

            col.modules.forEach((m, index) => {
              if (
                index === 0 &&
                m.side?.is_outside &&
                m.side?.width > widestHead
              ) {
                widestHead = m.side.width;
              } else if (
                index !== 0 &&
                m.head?.is_outside &&
                m.head.width > widestHead
              ) {
                widestHead = m.head.width;
              }
            });

            width += widestHead;

            if (isFirst && widestHead > widthOffsetStart) {
              widthOffsetStart = widestHead;
            }
            if (isLast && widestHead > widthOffsetEnd) {
              widthOffsetEnd = widestHead;
            }
          } else {
            if (isFirst && mod.side?.is_outside) {
              width += mod.side.width;
              if (mod.side.width > widthOffsetStart) {
                widthOffsetStart = mod.side.width;
              }
            }
            if (isLast && mod.side?.is_outside) {
              width += mod.side.width;
              if (mod.side.width > widthOffsetEnd) {
                widthOffsetEnd = mod.side.width;
              }
            }
          }
        }
        width += mod.body.width;

        // Get full Long && height
        let colLong = 0;
        col.modules.forEach((b, index) => {
          let modLong = b.body.long;
          if (index === 0 && b.head?.is_outside) modLong += b.head.long;
          if (
            index !== 0 &&
            index === col.modules.length - 1 &&
            b.side?.is_outside
          ) {
            modLong += b.side.long;

            if (b.side.long > widthOffsetTop) {
              widthOffsetTop = b.side.long;
            }
          }

          colLong += modLong;

          // Calc height
          if (b.body.height > height) height = b.body.height;
          if (b.side?.height > height) height = b.side.height;
          if (b.head?.height > height) height = b.head.height;
        });

        colsLong[index] = colLong;

        if (colLong > long) long = colLong;
      });

      // Get Long offsets
      this.columns.forEach((col, index) => {
        const mod = col.modules[0];
        const colLong = colsLong[index];

        const isLongestCol = Math.max(...colsLong) < colLong;

        if (mod.head?.is_outside && mod.head.long > longOffsetTop) {
          longOffsetTop = mod.head.long;
        }
        if (col.modules.length > 1 && isLongestCol) {
          const lastMod = col.modules[col.modules.length - 1];
          if (lastMod.side?.is_outside && lastMod.side.long > longOffsetBottom)
            longOffsetBottom = lastMod.side.long;
        }
      });

      return {
        height,
        width,
        widthOffsetStart: widthOffsetStart * this.scale * -1,
        widthOffsetEnd: widthOffsetEnd * this.scale * -1,
        widthOffsetTop: (widthOffsetTop + 10) * this.scale,
        long,
        long_secondary: [...colsLong].sort((a, b) => a - b).reverse()[1] || 0,
        colsLong,
        longOffsetTop: longOffsetTop * this.scale * -1,
        longOffsetBottom: longOffsetBottom * this.scale * -1,
      };
    },
  },
  methods: {
    viewFromTop() {
      if (this.animationActive) {
        this.animationActive = false;
      }
      this.preview = "top";
    },
    addExtra() {
      this.$emit("addExtra", {
        ci: this.selColIx,
      });
    },
    removeModule() {
      this.$emit("removeModule", {
        ci: this.selColIx,
        mi: this.selModIx,
      });
    },
    updateModule(value) {
      this.$emit("onUpdate", {
        ci: this.selColIx,
        mi: this.selModIx,
        value,
      });
    },
    updateHeight(val) {
      this.$emit("changeHeight", val);
    },
    updateBaseHeight(val) {
      this.$emit("changeBaseHeight", val);
    },
    updateHeadHeight(val) {
      this.$emit("changeHeadHeight", val);
    },
    updateSideHeight(val) {
      this.$emit("changeSideHeight", val);
    },
    updateWidth(val) {
      const col = this.columns[this.selColIx];

      col.modules.forEach((m, index) => {
        if (index === 0 && m.head) m.head.width = val;
        m.body.width = val;
      });
    },
    updateLong(val) {
      if (this.selModIx !== 0) return;

      this.columns.forEach((col) => {
        col.modules.forEach((m, mi) => {
          if (mi === this.selModIx) {
            if (m.side) {
              const headOutside = m.head?.is_outside;

              m.side.long = headOutside ? val + m.head.long : val;
            }

            m.body.long = val;
          }
        });
      });
    },
    zoomIn() {
      if (this.scale < 3) this.scale += 0.1;
    },
    zoomOut() {
      if (this.scale > 0.1) this.scale -= 0.1;
    },
    closeOptions() {
      this.showOptions = false;
      this.selColIx = null;
      this.selModIx = null;
    },
    openModuleOptions(columnIndex, moduleIndex) {
      this.selModIx = moduleIndex;
      this.selColIx = columnIndex;
      this.showOptions = true;
    },

    getWidthSizeOffset(ci) {
      let colOffset = this.fullSizes.long - this.fullSizes.colsLong[ci];

      if (this.columns[ci].modules.length > 1) {
        const lastMod =
          this.columns[ci].modules[this.columns[ci].modules.length - 1];
        if (lastMod.side?.is_outside) colOffset += lastMod.side.long;
      }

      return colOffset * this.scale + 40;
    },
  },
};
</script>
