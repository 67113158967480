<template>
  <div class="cubes-props-header">
    <h5>Propiedades</h5>
    <button @click="$emit('close')">&times;</button>
  </div>
  <div class="cubes-props-body">
    <div class="column-options" v-if="result">
      <button class="btn btn-danger mb-1" @click="removeCol">
        Eliminar Modulo
      </button>

      <button class="btn btn-primary" @click="addExtra">
        <i class="fa fa-plus mr-2"></i>
        Agregar Modulo adyacente
      </button>

      <small><b>Cuerpo</b></small>
      <FormNumberRange
        label="Alto de base"
        v-model="result.body.base_height"
        :min="0"
        :max="result.body.height"
      />
      <FormNumberRange label="Alto" v-model="result.body.height" />
      <FormNumberRange label="Largo" v-model="result.body.width" />
      <FormNumberRange label="Profundo" v-model="result.body.long" />

      <hr />

      <small><b>Respaldo</b></small>
      <div class="d-grid grid-2-cols">
        <FormCheckbox label="Respaldo" v-model="header" />
      </div>

      <template v-if="header">
        <FormNumberRange label="Alto" v-model="result.head.height" />
        <FormNumberRange label="Largo" v-model="result.head.width" />
        <FormNumberRange label="Profundo" v-model="result.head.long" />
      </template>

      <template v-if="canHaveSide">
        <hr />

        <small><b>Apoyabrazos</b></small>
        <div class="d-grid grid-2-cols">
          <FormCheckbox label="Apoyabrazos" v-model="side" />
        </div>

        <template v-if="side">
          <FormNumberRange label="Alto" v-model="result.side.height" />
          <FormNumberRange label="Largo" v-model="result.side.width" />
          <FormNumberRange label="Profundo" v-model="result.side.long" />
        </template>
      </template>

      <template v-if="preview">
        <hr />

        <p class="fw-600 small mb-1">Indicadores de tamaño total</p>
        <div class="d-grid grid-3-cols" v-if="preview">
          <FormCheckbox
            class="mt-2"
            label="Alto"
            v-model="totalSizes[preview].height"
            v-if="preview !== 'top'"
          />
          <FormCheckbox
            class="mt-2"
            label="Largo"
            v-model="totalSizes[preview].width"
            v-if="preview !== 'side'"
          />
          <FormCheckbox
            class="mt-2"
            label="Profundo"
            v-model="totalSizes[preview].long"
            v-if="preview !== 'front'"
          />
        </div>
        <small v-else>Cambia de vista para ver indicadores</small>
      </template>
    </div>
  </div>
</template>

<script>
import FormNumberRange from "./FormNumberRange.vue";
import FormCheckbox from "./FormCheckbox.vue";
import { deepCopy } from "@/utils/deepCopy";

export default {
  props: {
    cube: Object,
    isAdjacent: Boolean,
    isLeft: Boolean,
    canHaveSide: Boolean,
    preview: String,
    totalSizeIndicators: {
      type: Object,
      default: () => ({
        width: false,
        long: false,
        height: false,
      }),
    },
  },
  components: {
    FormNumberRange,
    FormCheckbox,
  },
  emits: [
    "close",
    "onUpdate",
    "onChangeHeight",
    "onChangeWidth",
    "onChangeLong",
    "onRemove",
    "onAddExtra",
    "onUpdateTotalSizes",
    "onChangeBaseHeight",
    "onChangeHeadHeight",
  ],
  data: () => ({
    header: false,
    side: false,
    result: null,
    loaded: false,
  }),
  computed: {
    totalSizes: {
      get() {
        return this.totalSizeIndicators;
      },
      set(val) {
        this.$emit("onUpdateTotalSizes", val);
      },
    },
  },
  watch: {
    result: {
      deep: true,
      handler(val) {
        if (!this.loaded) return;

        this.$emit("onUpdate", val);
      },
    },

    // Body changes
    "result.body.long"(val) {
      if (!this.loaded) return;
      if (this.isAdjacent) {
        if (this.result.head) this.result.head.long = val;
      } else {
        if (this.result.side) {
          const headOutside = this.result.head?.is_outside;

          this.result.side.long = headOutside
            ? val + this.result.head.long
            : val;
        }
      }
      // this.$emit('onChangeLong', val)
    },
    "result.body.width"(val) {
      if (!this.loaded) return;
      if (this.isAdjacent) {
        if (this.result.side) {
          const sideOutside = this.result.side?.is_outside;
          const headOutside = this.result.head?.is_outside;

          let width = val;
          if (headOutside && sideOutside) width += this.result.head.width;
          else if (!headOutside && !sideOutside)
            width -= this.result.head.width;
          this.result.side.width = width;
        }
      } else {
        if (this.result.head) this.result.head.width = val;
      }
      this.$emit("onChangeWidth", val);
    },
    "result.body.height"(val) {
      if (!this.loaded) return;

      if (this.result.body.base_height > val)
        this.result.body.base_height = val;

      this.$emit("onChangeHeight", val);
    },
    "result.body.base_height"(val) {
      if (!this.loaded) return;

      let newVal = val;
      if (val > this.result.body.height) {
        newVal = this.result.body.height;
        this.result.base_height = newVal;
      }

      this.$emit("onChangeBaseHeight", newVal);
    },

    // Side changes
    side(val) {
      if (!this.loaded) return;

      if (val) {
        if (!this.result.side) {
          this.result.side = {
            is_outside: true,
            long: !this.isAdjacent ? this.cube.body.long : 40,
            height: this.cube.body.height + 20,
            width: this.isAdjacent ? this.cube.body.width : 40,
            sizes: {
              top: { width: false, long: false, height: false },
              side: { width: false, long: false, height: false },
              front: { width: false, long: false, height: false },
            },
          };
        }
      } else {
        this.result.side = null;
      }
    },
    "result.side.is_outside"(val) {
      if (!this.loaded) return;

      if (!this.result.side) return;
      if (val) {
        this.result.side.height = this.cube.body.height + 20;
      } else {
        this.result.side.height = 20;

        if (this.isAdjacent) {
          let newWidth = this.result.body.width - this.result.head.width;
          if (this.result.head?.is_outside) newWidth += this.result.head.width;
          if (this.result.side.width > newWidth)
            this.result.side.width = newWidth;
        } else {
          let newLong = this.result.body.long - this.result.head.long;
          if (this.result.head?.is_outside) newLong += this.result.head.long;
          if (this.result.side.long > newLong) this.result.side.long = newLong;
        }
      }
    },
    "result.side.long"(val) {
      if (!this.loaded || !val) return;

      if (!this.isAdjacent) {
        let moduleLong = this.result.body.long;
        if (this.result.head?.is_outside) moduleLong += this.result.head.long;

        if (!this.result.side?.is_outside) moduleLong -= this.result.head.long;

        if (val > moduleLong) {
          this.result.side.long = moduleLong;
        }
      }
    },
    "result.side.width"(val) {
      if (!this.loaded || !val) return;

      if (this.isAdjacent) {
        let moduleWidth = this.result.body.width;
        if (this.result.head?.is_outside) moduleWidth += this.result.head.width;
        if (!this.result.side?.is_outside)
          moduleWidth -= this.result.head.width;

        if (val > moduleWidth) {
          this.result.side.width = moduleWidth;
        }
      }
    },
    // 'result.side.height'(val) {
    //   if (!this.loaded || !val) return

    //   this.$emit('onChangeSideHeight', val)
    // },

    // Head changes
    header(val) {
      if (!this.loaded) return;

      if (val) {
        if (!this.result.head) {
          this.result.head = {
            is_outside: true,
            long: this.isAdjacent ? this.cube.body.long : 40,
            height: this.cube.body.height + 20,
            width: !this.isAdjacent ? this.cube.body.width : 40,
            sizes: {
              top: { width: false, long: false, height: false },
              side: { width: false, long: false, height: false },
              front: { width: false, long: false, height: false },
            },
          };
        }
      } else {
        this.result.head = null;
      }
    },
    "result.head.is_outside"(val) {
      if (!this.loaded) return;

      if (!this.result.head) return;
      if (val) {
        this.result.head.height = this.cube.body.height + 20;
      } else {
        this.result.head.height = 20;
      }
    },
    "result.head.long"(val) {
      if (!this.loaded || !val) return;

      if (this.isAdjacent) {
        let moduleLong = this.result.body.long;

        if (val > moduleLong) {
          this.result.head.long = moduleLong;
        }
      }
    },
    "result.head.width"(val) {
      if (!this.loaded || !val) return;

      if (!this.isAdjacent) {
        let moduleWidth = this.result.body.width;

        if (val > moduleWidth) {
          this.result.head.width = moduleWidth;
        }
      }
    },
    // 'result.head.height'(val) {
    //   if (!this.loaded || !val) return

    //   this.$emit('onChangeHeadHeight', val)
    // }
  },
  mounted() {
    this.result = deepCopy(this.cube);
    this.header = !!this.result.head;
    this.side = !!this.result.side;
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
  methods: {
    removeCol() {
      this.$emit("onRemove");
      this.$emit("close");
    },
    addExtra() {
      this.$emit("onAddExtra");
    },
  },
};
</script>

<style></style>
