<template>
  <div class="card congrats-step">
    <div class="card-body">
      <i class="fa fa-regular fa-thumbs-up"></i>
      <h2>¡Gracias por confiar en nosotros!</h2>
      <p>
        En minutos recibirás tu presupuesto detallado por correo electrónico
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  data: () => ({}),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
