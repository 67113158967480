<template>
  <div id="cotizador">
    <section class="hero scroll-snap">
      <img src="/static/images/aboutus/aboutus-img3.webp" alt="" />

      <div class="steps-container">
        <TransitionGroup name="fade">
          <Step1 v-model="form" v-if="stepVisible === 1" @next="nextStep" />
          <Step2 v-model="form" v-if="stepVisible === 2" @next="nextStep" />
          <Step3 v-model="form" v-if="stepVisible === 3" @next="nextStep" />
          <Step4 v-model="form" v-if="stepVisible === 4" @next="nextStep" />
          <Step5 v-model="form" v-if="stepVisible === 5" @next="sendForm" />
          <Step6 v-model="form" v-if="stepVisible === 6" />
        </TransitionGroup>
      </div>
    </section>

    <section class="steps scroll-snap">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="step">
              <span class="icon icon-bold material-symbols-outlined">
                find_in_page
              </span>

              <div>
                <h3>Elegí tu modelo</h3>
                <p>
                  Selecciona entre nuestros modelos exclusivos el que más se
                  adapte a tu estilo.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="step">
              <span class="icon icon-bold material-symbols-outlined">
                palette
              </span>
              <div>
                <h3>Personalizalo a medida</h3>
                <p>Modifica cada detalle para que tu sillón sea único.</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="step">
              <span class="icon icon-bold material-symbols-outlined">
                request_quote
              </span>
              <div>
                <h3>Confirmá el pedido</h3>
                <p>
                  Obtén un precio claro y realiza tu pedido sin complicaciones.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="step">
              <span class="icon icon-bold material-symbols-outlined">
                local_shipping
              </span>
              <div>
                <h3>Recibilo en tu hogar</h3>
                <p>
                  Nos encargamos de todo, desde la producción hasta la entrega.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <TestimonialsComp />
  </div>
</template>

<script>
import TestimonialsComp from "../home/components/TestimonialsComp.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import Step6 from "./Step6.vue";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    TestimonialsComp,
  },
  props: {},
  data: () => ({
    stepVisible: null,
    form: {
      product_id: "JULA",
      modules_qty: 3,
      with_head: true,
      head_height: 90,
      head_long: 30,
      with_side: true,
      side_height: 80,
      side_width: 30,
      body_height: 60,
      total_width: 240,
      total_long: 100,
    },
  }),
  mounted() {
    this.nextStep();
  },
  methods: {
    nextStep(step = 1) {
      console.log(step);
      if (this.stepVisible !== null) {
        this.stepVisible = null;
        this.nextStep(step);
      } else {
        setTimeout(() => {
          this.stepVisible = step;
        }, 500);
      }
    },
    sendForm() {},
  },
};
</script>

<style></style>
