<template>
  <div class="card fabric-step">
    <div class="card-header">
      <h1>Nuestro catálogo de tela</h1>
      <p>
        Encontrá la tela perfecta que refleje tu estilo y resista el paso del
        tiempo. Elegí entre nuestra exclusiva colección de telas premium
        pensadas para cada espacio y necesidad.
      </p>
    </div>
    <div class="card-body">
      <div class="fabric-type" v-for="(f, ti) of fabricTypes" :key="f">
        <h6>{{ f.name }}</h6>
        <div class="fabric-catalog">
          <div
            class="item"
            :class="{ selected: selectedId === `${ti}_${i}` }"
            v-for="(a, i) of Array.from({ length: 10 }).entries()"
            :key="a"
            @click="selectedId = `${ti}_${i}`"
          >
            <img src="https://placehold.co/400" alt="" />
            <p>Tela {{ i + 1 }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button
        class="btn btn-primary btn-block"
        :disabled="!selectedId"
        @click="$emit('next', 5)"
      >
        Siguiente paso
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  data: () => ({
    fabricTypes: [
      {
        name: "Pana",
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
      {
        name: "Cuero",
        items: [1, 2, 3, 4, 5],
      },
      {
        name: "Pana",
        items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
      {
        name: "Cuero",
        items: [1, 2, 3, 4, 5],
      },
    ],
    selectedId: null,
  }),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
