<template>
  <div class="form-container">
    <label :for="itemId">{{ label }}</label>
    <div class="form-wrapper">
      <input
        type="range"
        :min="min"
        :max="max"
        :modelValue="result"
        @input="result = parseInt($event.target.value)"
      />
      <input type="number" :id="itemId" v-model="result" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    modelValue: [Number, String],
    min: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    result: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  data: () => ({
    itemId: null,
  }),
  mounted() {
    this.itemId = "range_" + Math.floor(Math.random() * Date.now()).toString();
  },
};
</script>

<style></style>
