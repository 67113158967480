<template>
  <div class="form-container" :class="{ disabled, 'flex-field': flexField }">
    <label :for="itemId" v-if="label"
      >{{ label }} <small v-if="description">{{ description }}</small></label
    >
    <div class="form-wrapper">
      <div class="icon" v-if="icon">
        <i :class="icon"></i>
      </div>
      <input
        type="number"
        placeholder=" "
        :id="itemId"
        v-model="result"
        :disabled="disabled"
        @focus="onFocus"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, String],
      default: null,
    },
    label: String,
    description: String,
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    flexField: {
      type: Boolean,
      default: false,
    },
    selectOnFocus: Boolean,
    max: Number,
  },
  data: () => ({
    itemId: null,
  }),
  computed: {
    result: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value !== "" ? value : null);
      },
    },
  },
  watch: {
    result(val) {
      if (this.max === undefined) return;

      if (val > this.max) {
        this.result = this.max;
      }
    },
  },
  mounted() {
    this.fillId();
  },
  methods: {
    fillId() {
      this.itemId = Math.floor(Math.random() * Date.now());
    },
    onFocus(e) {
      if (this.selectOnFocus) e.target.select();
    },
  },
};
</script>

<style></style>
