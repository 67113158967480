<template>
  <div class="card fabric-step">
    <div class="card-header">
      <h1>Tu sillón soñado está más cerca de lo que creés</h1>
      <p>Completá tus datos y recibí tu presupuesto al instante.</p>
    </div>
    <div class="card-body">
      <FormText label="Nombre completo" />
      <FormText label="Email" />
      <FormText label="Whatsapp" />
      <FormText label="Comentarios adicionales" textarea />
    </div>

    <div class="card-footer">
      <button
        class="btn btn-primary btn-block"
        :disabled="!formCompleted"
        @click="$emit('next', 6)"
      >
        Obtener presupuesto
      </button>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/FormText.vue";

export default {
  props: {
    modelValue: Object,
  },
  data: () => ({
    FormText,
  }),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    formCompleted() {
      return false;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
