<template>
  <div class="card">
    <div class="card-header">
      <h1>Diseña el sillón perfecto para tu hogar en minutos</h1>
      <p>
        Obtené en minutos una cotización exacta para tu sillón a medida, agendá
        una llamada y recibilo en tu casa. ¡Nunca fue tan fácil!
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <FormSelect
            label="Seleccioná tu modelo"
            :options="modelos"
            v-model="form.product_id"
          />
        </div>
        <div class="col-md-12">
          <FormNumber label="Cantidad de cuerpos" v-model="form.modules_qty" />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button class="btn btn-primary btn-block" @click="$emit('next', 2)">
        Obtener cotización
      </button>
    </div>
  </div>
</template>

<script>
import FormNumber from "@/components/Form/FormNumber.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default {
  components: { FormNumber, FormSelect },
  props: {
    modelValue: Object,
  },
  data: () => ({
    modelos: [
      "SOHO",
      "JULA",
      "HIT",
      "DARUMA",
      "BAYBOO",
      "CHESTERFIELD",
      "TOGO",
    ],
  }),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style></style>
