<template>
  <div class="card">
    <div class="card-header">
      <h1>Modelo 3D</h1>
      <p>
        Aca podés ver una representación de las dimensiones de tu próximo
        sillón. Te gustaría hacer algun cambio? Hace click en cualquier módulo y
        modificá sus dimensiones.
      </p>
    </div>
    <div class="card-body">
      <PrototypeComp :columns="prototype" @updateData="updateFormData" />
    </div>

    <div class="card-footer">
      <button class="btn btn-primary btn-block" @click="$emit('next', 4)">
        Siguiente paso
      </button>
    </div>
  </div>
</template>

<script>
import PrototypeComp from "@/components/Prototype/PrototypeComp.vue";

export default {
  components: { PrototypeComp },
  props: {
    modelValue: Object,
  },
  data: () => ({
    prototype: [],
  }),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  mounted() {
    // this.prototype = this.getDefaultColumns();
    this.createPrototype();
  },
  methods: {
    createPrototype() {
      const modWidth = Math.floor(
        this.form.total_width / this.form.modules_qty
      );
      const head = {
        is_outside: true,
        width: modWidth,
        long: this.form.head_long,
        height: this.form.head_height,
        sizes: {
          top: { width: false, long: false, height: false },
          side: { width: false, long: false, height: false },
          front: { width: false, long: false, height: false },
        },
      };
      const side = {
        is_outside: true,
        long: this.form.total_long,
        height: this.form.side_height,
        width: this.form.side_width,
        sizes: {
          top: { width: false, long: false, height: false },
          side: { width: false, long: false, height: false },
          front: { width: false, long: false, height: false },
        },
      };

      const prototype = Array.from(
        { length: this.form.modules_qty },
        (_, i) => {
          return {
            modules: [
              {
                body: {
                  base_height: 20,
                  width: modWidth,
                  long: this.form.total_long,
                  height: this.form.body_height,
                  sizes: {
                    top: { width: false, long: false, height: false },
                    side: { width: false, long: false, height: false },
                    front: { width: false, long: false, height: false },
                  },
                },
                head: this.form.with_head ? head : null,
                side:
                  this.form.with_side &&
                  (i === 0 || i === this.form.modules_qty - 1)
                    ? side
                    : null,
              },
            ],
          };
        }
      );

      this.prototype = prototype;
    },
    getDefaultColumns() {
      return [
        {
          modules: [
            {
              body: {
                base_height: 20,
                width: 90,
                long: 120,
                height: 60,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              head: {
                is_outside: true,
                width: 90,
                long: 40,
                height: 80,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              side: {
                is_outside: true,
                position: "left",
                long: 160,
                height: 80,
                width: 40,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
            },
          ],
        },
        {
          modules: [
            {
              body: {
                base_height: 20,
                width: 90,
                long: 120,
                height: 60,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              head: {
                is_outside: true,
                width: 90,
                long: 40,
                height: 80,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              side: null,
            },
          ],
        },
        {
          modules: [
            {
              body: {
                base_height: 20,
                width: 90,
                long: 120,
                height: 60,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              head: {
                is_outside: true,
                width: 90,
                long: 40,
                height: 80,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
              side: {
                is_outside: true,
                position: "right",
                long: 160,
                height: 80,
                width: 40,
                sizes: {
                  top: { width: false, long: false, height: false },
                  side: { width: false, long: false, height: false },
                  front: { width: false, long: false, height: false },
                },
              },
            },
          ],
        },
      ];
    },
    updateFormData(data) {
      this.prototype = data;
    },
  },
};
</script>

<style></style>
