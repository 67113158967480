<template>
  <div class="cube-main-layout">
    <CubeContainer
      :columns="localColumns"
      @changeHeight="updateHeight"
      @changeBaseHeight="updateBaseHeight"
      @changeHeadHeight="updateHeadHeight"
      @changeSideHeight="updateSideHeight"
      @onUpdate="updateModule"
      @addColumn="addModule"
      @addExtra="addAdjacenteModule"
      @removeModule="removeModule"
    />
  </div>
</template>

<script setup>
import { inject, ref, watch } from "vue";
import { defineEmits, defineProps } from "vue";
import CubeContainer from "./CubeContainer.vue";
import { deepCopy } from "@/utils/deepCopy";
const $toast = inject("$toast");

const props = defineProps({
  columns: Array,
});

const emit = defineEmits(["updateData"]);

const localColumns = ref([...props.columns]);

watch(
  () => props.columns,
  (newVal) => {
    localColumns.value = [...newVal];
  },
  { immediate: true }
);

const baseModule = {
  head: null,
  side: null,
  body: {
    width: 90,
    long: 120,
    height: 60,
  },
};

function updateHeight(value) {
  let columns = localColumns.value;

  columns = columns.map((column) => {
    column.modules = column.modules.map((module) => {
      module.body.height = value;

      return module;
    });

    return column;
  });

  emit("updateData", columns);
}

function updateBaseHeight(value) {
  let columns = localColumns.value;

  columns = columns.map((column) => {
    column.modules = column.modules.map((module) => {
      module.body.base_height = value;

      return module;
    });

    return column;
  });

  emit("updateData", columns);
}

function updateHeadHeight(value) {
  let columns = localColumns.value;

  columns = columns.map((column) => {
    column.modules = column.modules.map((module) => {
      if (module.head) {
        module.head.height = value;
      }

      return module;
    });

    return column;
  });

  emit("updateData", columns);
}

function updateSideHeight(value) {
  let columns = localColumns.value;

  columns = columns.map((column) => {
    column.modules = column.modules.map((module) => {
      if (module.side) {
        module.side.height = value;
      }

      return module;
    });

    return column;
  });

  emit("updateData", columns);
}

function updateModule({ ci, mi, value }) {
  localColumns.value[ci].modules[mi] = value;
  emit("updateData", localColumns.value);
}

function removeModule({ ci, mi }) {
  if (localColumns.value.length === 1 && mi === 0) {
    $toast.error("No se puede eliminar el último módulo");
    return;
  }
  if (localColumns.value[ci].modules.length === 1 || mi === 0) {
    localColumns.value = localColumns.value.filter((_, i) => i !== ci);
  } else {
    localColumns.value[ci].modules = localColumns.value[ci].modules.filter(
      (_, i) => i !== mi
    );
  }
  emit("updateData", localColumns.value);
}

function addModule() {
  const columnsAux = deepCopy(localColumns.value);

  const lastColumnIx = columnsAux.length - 1;
  const lastColumn = columnsAux[lastColumnIx];
  const newColumn = {
    modules: [
      {
        ...deepCopy(lastColumn.modules[0] || baseModule),
        side: null,
      },
    ],
  };

  columnsAux.splice(lastColumnIx, 0, newColumn);

  emit("updateData", columnsAux);
}

function addAdjacenteModule({ ci }) {
  const columnsAux = deepCopy(localColumns.value);
  const { body, side, head } = deepCopy(columnsAux[ci].modules).pop();
  const modsLength = columnsAux[ci].modules.length;

  let newModule = {
    body,
    side,
    head,
  };

  if (modsLength > 1) {
    newModule.side = null;
    columnsAux[ci].modules.splice(modsLength - 1, 0, newModule);
  } else {
    newModule = {
      body,
      head: null,
      side: null,
    };

    if (side) {
      newModule.head = {
        ...side,
        long: body.long,
      };
    }

    columnsAux[ci].modules.push(newModule);

    // update side long
    const module = columnsAux[ci].modules[modsLength - 1];
    module.side.long =
      module.body.long + (module.head?.is_outside ? module.head.long : 0);
  }

  emit("updateData", columnsAux);
}
</script>
