<template>
  <div class="form-container form-checkbox">
    <label :for="itemId">{{ label }}</label>
    <input :id="itemId" type="checkbox" :value="true" v-model="result" />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    modelValue: [Boolean, String],
  },
  computed: {
    result: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  data: () => ({
    itemId: null,
  }),
  mounted() {
    this.itemId =
      "checkbox_" + Math.floor(Math.random() * Date.now()).toString();
  },
};
</script>

<style></style>
