<template>
  <div
    class="cube"
    :style="cubeStyles"
    :class="{ 'is-adjacent': isAdjacent, 'is-right': isRight }"
  >
    <div
      class="face front"
      :w="parseInt(width).toFixed(0)"
      :l="parseInt(long).toFixed(0)"
      :h="parseInt(height).toFixed(0)"
    >
      <div
        class="size-width"
        v-if="showWidthSize"
        :style="`--offset: ${widthSizeOffset}px;`"
      >
        <span>{{ width }}cm</span>
      </div>
      <div
        class="size-height"
        v-if="showHeightSize"
        :style="`--offset: ${heightSizeOffset}px;`"
      >
        <span>{{ height }}cm</span>
      </div>
    </div>
    <div
      class="face back"
      :w="parseInt(width).toFixed(0)"
      :l="parseInt(long).toFixed(0)"
      :h="parseInt(height).toFixed(0)"
    />
    <div
      class="face left"
      :w="parseInt(width).toFixed(0)"
      :l="parseInt(long).toFixed(0)"
      :h="parseInt(height).toFixed(0)"
    >
      <div class="sizes-wrapper">
        <div
          class="size-long"
          v-if="showLongSize"
          :style="`--offset: ${longSizeOffset}px;`"
        >
          <span>{{ long }}cm</span>
        </div>
        <div
          class="size-height"
          v-if="showHeightSize"
          :style="`--offset: ${heightSizeOffset}px;`"
        >
          <span>{{ height }}cm</span>
        </div>
      </div>
    </div>
    <div
      class="face right"
      :w="parseInt(width).toFixed(0)"
      :l="parseInt(long).toFixed(0)"
      :h="parseInt(height).toFixed(0)"
    />
    <div
      class="face top"
      :w="parseInt(width).toFixed(0)"
      :l="parseInt(long).toFixed(0)"
      :h="parseInt(height).toFixed(0)"
    >
      <div
        class="size-long"
        v-if="showLongSize"
        :style="`--offset: ${longSizeOffset}px;`"
      >
        <span>{{ long }}cm</span>
      </div>
      <div
        class="size-width"
        v-if="showWidthSize"
        :style="`--offset: ${widthSizeOffset}px;`"
      >
        <span>{{ width }}cm</span>
      </div>
    </div>

    <Cube
      v-if="head"
      :height="head.height"
      :long="head.long"
      :width="head.width"
      :baseOffset="headProps.baseOffset"
      :backOffset="headProps.backOffset"
      :sideOffset="headProps.sideOffset"
      :scale="scale"
      class="head"
      :class="{
        left: isLeft && isAdjacent,
        right: !isLeft && isAdjacent,
      }"
      :longSizeOffset="longSizeOffset"
      :widthSizeOffset="widthSizeOffset"
      :heightSizeOffset="heightSizeOffset + 40"
      :showLongSize="head.sizes[preview]?.long"
      :showWidthSize="head.sizes[preview]?.width"
      :showHeightSize="head.sizes[preview]?.height"
    />
    <Cube
      v-if="side"
      :height="side.height"
      :long="side.long"
      :width="side.width"
      :baseOffset="sideProps.baseOffset"
      :backOffset="sideProps.backOffset"
      :sideOffset="sideProps.sideOffset"
      class="side"
      :class="isLeft ? 'left' : 'right'"
      :scale="scale"
      :longSizeOffset="longSizeOffset"
      :widthSizeOffset="widthSizeOffset"
      :heightSizeOffset="heightSizeOffset + 40"
      :showLongSize="side.sizes[preview]?.long"
      :showWidthSize="side.sizes[preview]?.width"
      :showHeightSize="side.sizes[preview]?.height"
    />
    <Cube
      v-if="isAlone && side"
      :height="side.height"
      :long="side.long"
      :width="side.width"
      :baseOffset="sideProps.baseOffset"
      :backOffset="sideProps.backOffset"
      :sideOffset="sideProps.sideOffset"
      class="side"
      :class="'right'"
      :scale="scale"
      :longSizeOffset="longSizeOffset"
      :widthSizeOffset="widthSizeOffset"
      :heightSizeOffset="heightSizeOffset + 40"
      :showLongSize="side.sizes[preview]?.long"
      :showWidthSize="side.sizes[preview]?.width"
      :showHeightSize="side.sizes[preview]?.height"
    />
  </div>
</template>

<script>
export default {
  name: "Cube",
  props: {
    long: {
      type: [Number, String],
      default: 120,
    },
    height: {
      type: [Number, String],
      default: 60,
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    scale: {
      type: [Number, String],
      default: 1,
    },
    base_height: [Number, String],
    baseOffset: [Number, String],
    backOffset: [Number, String],
    sideOffset: [Number, String],
    isOutside: Boolean,
    head: Object,
    side: Object,
    isAdjacent: Boolean,
    isLeft: Boolean,
    isRight: Boolean,
    isAlone: Boolean,
    longestColumn: Number,
    preview: String,

    // Sizes show props
    showLongSize: Boolean,
    showWidthSize: Boolean,
    showHeightSize: Boolean,

    // Sizes offset props
    longSizeOffset: Number,
    widthSizeOffset: Number,
    heightSizeOffset: Number,
  },
  computed: {
    headProps() {
      let baseOffset = this.height / 2;
      if (this.head?.is_outside) {
        baseOffset = baseOffset * -1;
      }

      let backOffset = 0;
      if (!this.isAdjacent && this.head?.is_outside) {
        backOffset = this.head.long * -1;
      }

      let sideOffset = 0;
      if (this.head?.is_outside) {
        sideOffset = this.head?.width * -1;
      }

      return {
        baseOffset,
        backOffset,
        sideOffset,
      };
    },
    sideProps() {
      let baseOffset = this.height / 2;
      if (this.side?.is_outside) {
        baseOffset = baseOffset * -1;
      }

      let backOffset = 0;
      if (this.isAdjacent) {
        backOffset = this.long;
        if (!this.side.is_outside) {
          backOffset = this.long - this.side.long;
        }
      } else {
        if (this.head?.is_outside && this.side?.is_outside) {
          backOffset = this.head.long * -1;
        } else if (
          this.head &&
          !this.head.is_outside &&
          !this.side?.is_outside
        ) {
          backOffset = this.head.long;
        }
      }

      let sideOffset = 0;
      if (this.isAdjacent) {
        if (this.head?.is_outside && this.side?.is_outside) {
          sideOffset = this.head.width * -1;
        } else if (
          this.head &&
          !this.head.is_outside &&
          !this.side?.is_outside
        ) {
          sideOffset = this.head.width;
        }
      } else if (this.side?.is_outside) {
        sideOffset = this.side?.width * -1;
      }

      return {
        baseOffset,
        backOffset,
        sideOffset,
      };
    },
    cubeStyles() {
      let styles = `
        --width: ${Math.floor(this.width * this.scale)}px;
        --height: ${Math.floor(this.height * this.scale)}px;
        --base-height: ${Math.floor(this.base_height * this.scale)}px;
        --long: ${this.long * this.scale}px;
        --backOffset: ${this.backOffset ? this.backOffset * this.scale : 0}px;
        --baseOffset: ${this.baseOffset ? this.baseOffset * this.scale : 0}px;
        --sideOffset: ${this.sideOffset ? this.sideOffset * this.scale : 0}px;
      `;

      return styles;
    },
  },
};
</script>
