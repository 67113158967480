<template>
  <div class="card">
    <div class="card-header">
      <h1>Seamos más específicos</h1>
      <p>
        Los datos que ves a continuación son las medidas por defecto del modelo
        que elegiste. Personalizalo a tu gusto
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <FormCheckbox label="Con cabezal" v-model="form.with_head" />
        </div>
        <div class="col-md-6" v-if="form.with_head">
          <FormNumber label="Alto cabezal" v-model="form.head_height" />
        </div>
        <div class="col-md-6" v-if="form.with_head">
          <FormNumber label="Grosor de cabezal" v-model="form.head_long" />
        </div>
      </div>

      <hr class="my-3" />

      <div class="row">
        <div class="col-12">
          <FormCheckbox label="Con apoyabrazos" v-model="form.with_side" />
        </div>
        <div class="col-md-6" v-if="form.with_side">
          <FormNumber label="Alto apoyabrazos" v-model="form.side_height" />
        </div>
        <div class="col-md-6" v-if="form.with_side">
          <FormNumber label="Grosor de apoyabrazos" v-model="form.side_width" />
        </div>
      </div>

      <hr class="my-3" />

      <div class="row">
        <div class="col-md-4">
          <FormNumber label="Alto asiento" v-model="form.body_height" />
        </div>
        <div class="col-md-4">
          <FormNumber label="Ancho total" v-model="form.total_width" />
        </div>
        <div class="col-md-4">
          <FormNumber label="Profundidad total" v-model="form.total_long" />
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button class="btn btn-primary btn-block" @click="$emit('next', 3)">
        Siguiente paso
      </button>
    </div>
  </div>
</template>

<script>
import FormCheckbox from "@/components/Form/FormCheckbox.vue";
import FormNumber from "@/components/Form/FormNumber.vue";

export default {
  components: {
    FormNumber,
    FormCheckbox,
  },
  props: {
    modelValue: Object,
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<style></style>
